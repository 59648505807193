<template>
    <div>
        <multiselect
            v-model="selectedTags"
            :options="formattedTags"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :show-labels="false"
            placeholder="Select tags"
            label="name"
            track-by="id"
            class="tag-multiselect"
            @input="updateSelectedTags"
        >
            <template #tag="{ option, remove }">
                <span :style="{ backgroundColor: option.color }" class="tag-color"></span>
            </template>
            <template #option="{ option, isSelected, isHighlighted }">
                <div
                    class="custom-option"
                    :class="{ 'option-highlighted': isHighlighted, 'option-selected': isSelected }"
                >
                    <span :style="{ backgroundColor: option.color }" class="tag-color"></span>
                    {{ option.name }}
                </div>
            </template>
        </multiselect>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';

    export default {
        components: { Multiselect },
        props: {
            availableTags: {
                type: Array,
                required: true,
            },
            selectedInvoiceTags: {
                type: Array,
                default: null,
            },
        },
        computed: {
            formattedTags() {
                return this.availableTags.map(tag => ({
                    id: tag.attributes.id,
                    name: tag.attributes.name,
                    color: tag.attributes.color,
                }));
            },
        },
        data() {
            return {
                selectedTags: [],
            };
        },
        created() {
            this.selectedTags = this.selectedInvoiceTags;
        },
        methods: {
            updateSelectedTags() {
                this.$emit('update:selectedTags', this.selectedTags);
            },
        },

        watch: {
            selectedInvoiceTags(value) {
                this.selectedTags = value;
            },
        },
    };
</script>

<style lang="scss">
    .tag-multiselect {
        border-radius: 5px;
        width: 100%;

        .multiselect__option--highlight {
            background-color: #007bff !important;
            color: #fff !important;
        }

        .multiselect__option--selected {
            background-color: #dc143c !important;
            color: #fff !important;
        }

        .theme-dark & {
            background-color: #555;
            color: #ffa500;

            .multiselect__tags,
            .multiselect__tags-wrap,
            .multiselect__input,
            .multiselect__single {
                background-color: #555;
                color: #ffa500;
                display: flex;
            }

            .multiselect__option--highlight {
                background-color: #555 !important;
                color: #fff !important;
            }

            .multiselect__option--selected {
                background-color: #333 !important;
                color: #fff !important;
            }

            &:focus {
                border-color: #66afe9;
                box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
            }
        }
    }
    .multiselect__tags {
        height: 39px;
        border: 1px solid #ccc;
        .theme-dark & {
            border-color: #555;
            border-radius: 5px;
        }
    }
    .multiselect__content-wrapper {
        .theme-dark & {
            border: 1px solid #555;
        }
    }
    .custom-tag {
        display: inline-flex;
        align-items: center;
        padding: 2px 8px;
        margin-right: 4px;
        border-radius: 12px;
        font-size: 13px;
        color: #fff;
    }

    .tag-color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
        margin-left: 5px;
    }

    .custom-tag-remove {
        margin-left: 8px;
        cursor: pointer;
    }

    .custom-option {
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 5px;
    }

    .option-highlighted {
        background-color: #00f !important;
        color: #fff !important;
    }

    .option-selected {
        background-color: #f00 !important;
        color: #fff !important;
    }

    .theme-dark .multiselect__tags,
    .theme-dark .multiselect__tags-wrap,
    .theme-dark .multiselect__input,
    .theme-dark .multiselect__single {
        background-color: #555;
        color: #ffa500;
    }

    .theme-dark .multiselect__content {
        background-color: #444;
        color: #ffa500;
    }

    .theme-dark .multiselect__option--highlight {
        background-color: #1f1f1f !important;
        color: #fff !important;
    }

    .theme-dark .multiselect__option--selected {
        background-color: #333 !important;
        color: #fff !important;
    }

    .theme-dark .multiselect:focus {
        border-color: #66afe9;
        box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
    }

    @media (max-width: 768px) {
        .multiselect__tags {
            width: 200px;
        }
    }
</style>
