<template>
    <div class="kanban-column">
        <div class="kanban-header">
            <h3>{{ currentColumn.attributes.name }}</h3>
            <button v-if="buttonSettingsAccess" class="settings-button" @click="openSettings">&#x22EE;</button>
        </div>

        <kanban-column-settings
            v-if="isModalOpen"
            :isOpen="isModalOpen"
            :currentColumn="currentColumn"
            :columnStatuses="statuses"
            :usedStatusesID="usedStatusesID"
            @close="closeModal"
        />

        <div
            v-for="status in statuses"
            :key="status.id"
            class="kanban-status"
            :data-status-id="status.id"
            :data-column-id="currentColumn.attributes.id"
            :class="{ 'drag-over': isDragOver[status.id] }"
            @dragenter.prevent="onDragEnter(status.id)"
            @dragover.prevent="onDragOver(status.id)"
            @dragleave="onDragLeave(status.id, $event)"
            @drop="dropCard(currentColumn.attributes.id, status.id)"
        >
            <h4>{{ status.name }}</h4>
            <kanban-card
                v-for="card in status.cards"
                :key="card.attributes.id"
                :card="card"
                :columnID="currentColumn.attributes.id"
                :statusID="status.id"
                @dragStart="dragStart"
                @drag-hover-card="dragTargerCard"
            />
        </div>
    </div>
</template>

<script>
    import KanbanColumnSettings from '../../components/KanbanColumnSettings.vue';
    import KanbanCard from './KanbanCard.vue';
    import RolePolicy from '../../../../policies/role.policy';

    export default {
        components: {
            KanbanCard,
            KanbanColumnSettings,
        },
        props: {
            currentColumn: Object,
            usedStatusesID: Array,
        },
        data() {
            return {
                isDragOver: {},
                isModalOpen: false,
                statuses: this.currentColumn.attributes.statuses,
                startX: 0,
                startY: 0,
            };
        },
        computed: {
            buttonSettingsAccess() {
                return RolePolicy.haveRole(['admin']);
            },
        },
        created() {
            this.statuses = this.statuses.filter(status => status.visible);
        },
        methods: {
            dragStart(card, columnID, statusID) {
                this.$emit('dragStart', card, columnID, statusID);
            },
            dragTargerCard(card) {
                this.$emit('drag-hover', card);
            },
            dropCard(columnID, statusID) {
                this.$emit('moveCard', columnID, statusID);
                this.$set(this.isDragOver, statusID, false);
            },
            onDragEnter(statusID) {
                this.$set(this.isDragOver, statusID, true);
            },
            onDragOver(statusID) {
                this.$set(this.isDragOver, statusID, true);
            },
            onDragLeave(statusID, event) {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                    this.$set(this.isDragOver, statusID, false);
                }
            },
            openSettings() {
                this.isModalOpen = true;
            },
            closeModal() {
                this.isModalOpen = false;
            },
        },
        watch: {
            'currentColumn.attributes.statuses': {
                handler(newStatuses) {
                    this.statuses = newStatuses;
                },
                deep: true,
            },
        },
    };
</script>

<style scoped lang="scss">
    .kanban-column {
        background-color: #eaeaea;
        border-radius: 10px;
        padding: 10px;
        width: 250px;
        min-width: 250px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        flex: 1 1 calc(25% - 20px);
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;

        .theme-dark & {
            background-color: #333;
        }
    }

    .kanban-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .kanban-header h3 {
        margin: 0;
        text-align: center;
        color: #333;
        font-weight: bold;
        font-size: 18px;
        flex: 1;
    }

    .settings-button {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
        color: #333;

        &:hover {
            columns: #000;
        }

        .theme-dark & {
            color: #ffa500;

            &:hover {
                color: #c4c4cf;
            }
        }
    }
    .kanban-status {
        padding: 10px;
        border: 2px dashed #ccc;
        border-radius: 6px;
        background-color: #fafafa;
        min-height: 100px;
        transition: background-color 0.3s ease;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
        }
    }

    .kanban-status h4 {
        margin-bottom: 10px;
        color: #666;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
    }

    .kanban-status.drag-over {
        background-color: #e0f7fa;
        border-color: #00acc1;
        transition: background-color 0.3s ease, border-color 0.3s ease;

        .theme-dark & {
            background-color: #444;
            border-color: #ffa500;
        }
    }
    .kanban-column,
    .kanban-status {
        scrollbar-width: thin;
        scrollbar-color: #888 #eaeaea;
    }

    .kanban-column::-webkit-scrollbar,
    .kanban-status::-webkit-scrollbar {
        width: 8px;
    }

    .kanban-column::-webkit-scrollbar-thumb,
    .kanban-status::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
    }

    .kanban-column::-webkit-scrollbar-track,
    .kanban-status::-webkit-scrollbar-track {
        background-color: #eaeaea;
    }
</style>
