<template>
    <div class="invoice">
        <div class="graph">
            <invoiceGrapth :ownInvoices="true"></invoiceGrapth>
        </div>
        <div class="list">
            <InvoiceList :ownInvoices="true"></InvoiceList>
        </div>
    </div>
</template>

<script>
    import InvoiceList from '../../components/InvoiceList';
    import InvoiceGrapth from '../../components/InvoiceGrapth.vue';

    export default {
        name: 'Outgoing',
        components: {
            InvoiceList,
            InvoiceGrapth,
        },
    };
</script>
<style lang="scss" scoped>
    .graph {
        margin-bottom: 20px;
    }

    @media (max-width: 550px) {
        .graph {
            height: auto;
        }
    }
</style>
