import axios from 'axios';

class KanbanColumnService {
    save(data) {
        return axios.post('/api/v1/kanban_columns', data);
    }

    getAll() {
        return axios.get('/api/v1/kanban_columns');
    }

    update(data, id) {
        return axios.put(`/api/v1/kanban_columns/${id}`, data);
    }

    delete(id) {
        return axios.delete(`/api/v1/kanban_columns/${id}`);
    }

    addStatusToColumn(data) {
        return axios.post('/api/v1/kanban_column_statuses', data);
    }

    removeStatusFromColumn(data) {
        return axios.delete('/api/v1/kanban_column_status/remove', { data: data });
    }
}

export default KanbanColumnService;
