import axios from 'axios';

class InvoiceService {
    update(data, id) {
        return axios.put(`/api/v1/invoices/${id}`, data);
    }

    save(data) {
        return axios.post('/api/v1/invoices', data);
    }

    getAll() {
        return axios.post('/api/v1/invoices/list');
    }

    getWithFilters(filters) {
        return axios.post('/api/v1/invoices/list', filters);
    }

    getById(id) {
        return axios.get(`/api/v1/invoices/${id}`);
    }

    bulkDestroy(data) {
        return axios.post('/api/v1/invoices/bulk-destroy', data);
    }
}

export default InvoiceService;
