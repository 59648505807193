<template>
    <div class="client-users">
        <div class="client-users__content-wrapper">
            <router-view :key="$route.fullPath" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'client-users',
    };
</script>
