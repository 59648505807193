<template>
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
            <div class="content">
                <div class="button-header">
                    <a class="close-button" @click="closeModal">✖</a>
                </div>
                <div class="modal-header">
                    <p class="modal-date">{{ formattedDate }}</p>
                    <p class="modal-amount">{{ formattedAmount }}</p>
                </div>
                <div class="modal-body">
                    <div ref="tagSelector" class="tag-selector">
                        <div class="tag-window">
                            <label v-for="tag in availableTags" :key="tag.attributes.id" class="tag-option">
                                <input
                                    v-model="selectedTags"
                                    type="checkbox"
                                    :value="tag.attributes.id"
                                    @change="updateTags"
                                />
                                <span :style="{ backgroundColor: tag.attributes.color }" class="tag-color"></span>
                                <p>{{ tag.attributes.name }}</p>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            initialSelectedTags: {
                type: Array,
                default: () => [],
            },
            invoice: {
                type: Object,
                required: true,
            },
            showModal: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                selectedTags: [],
                availableTags: [],
            };
        },
        computed: {
            formattedDate() {
                return new Date(this.invoice.attributes.date).toISOString().split('T')[0];
            },
            formattedAmount() {
                return `${this.invoice.attributes.amount_format}`;
            },
        },
        async mounted() {
            await this.fetchTags();
            this.selectedTags = this.initialSelectedTags.map(tag => tag.id);
        },
        methods: {
            async fetchTags() {
                this.availableTags = await this.$store.dispatch('tag/getTags');
            },
            updateTags() {
                this.$emit('update-tags', this.invoice.attributes.id, this.selectedTags);
            },
            closeModal() {
                this.invoice.showTagPopup = false;
            },
            handleClickOutside(event) {
                if (this.$refs.tagSelector && !this.$refs.tagSelector.contains(event.target)) {
                    this.showTagWindow = false;
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
        width: 100%;
    }

    .modal-content {
        background: rgba(0, 0, 0, 0.1);
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;
        max-width: 800px;
        margin-left: 150px;

        @media (min-width: 1400px) {
            max-width: 1100px;
            margin-left: 200px;
        }
    }

    .close-button {
        position: absolute;
        top: 5px;
        right: 10px;
        background: none;
        border: none;
        font-size: 25px;
        cursor: pointer;
    }

    @media (max-width: 992px) {
        .modal-content {
            margin-left: 0px;
        }
    }

    .content {
        background: #fff;
        border-radius: 8px;
        z-index: 21;

        .theme-dark & {
            background: #333;
        }
    }

    .modal-header {
        margin-bottom: 20px;
        text-align: center;
    }

    .modal-date,
    .modal-amount {
        font-size: 18px;
        margin: 0;
    }

    .modal-body {
        margin-bottom: 20px;
    }

    .button-header {
        display: flex;
        justify-content: right;
    }

    .tag-selector {
        position: relative;
    }

    .tag-window {
        margin: 3px;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .tag-option {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .tag-option:last-child {
        margin-bottom: 0;
    }

    .tag-color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 5px;
    }

    button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;
        .theme-dark & {
            color: #ffa500;
            background-color: rgb(40, 40, 40);

            &:hover {
                background-color: #555;
            }
        }
    }

    button:hover {
        background-color: #0056b3;
    }

    @media (max-width: 992px) {
        .modal-content {
            margin-left: 0px;
        }
    }
</style>
