<template>
    <div class="modal-overlay">
        <div class="modal">
            <button class="close-button" @click="$emit('close')">&times;</button>

            <h2>{{ $t('components.kanbanBoard.title') }}</h2>

            <form @submit.prevent="validateParams">
                <div class="new-column-form">
                    <at-input
                        v-model="newColumnName"
                        type="text"
                        :placeholder="$t('components.kanbanBoard.namePlaceholder')"
                    />
                    <span v-if="errorMessage" class="error-message">{{ errorMessage }}</span>
                </div>

                <div class="modal-actions">
                    <button
                        type="submit"
                        class="at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                    >
                        {{ $t('components.kanbanBoard.buttons.save') }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import KanbanColumnService from '../services/kanban-column.service';

    export default {
        data() {
            return {
                newColumnName: '',
                errorMessage: '',
                service: new KanbanColumnService(),
            };
        },
        methods: {
            validateParams() {
                if (!this.newColumnName.trim()) {
                    this.errorMessage = this.$t('components.kanbanBoard.errors.name');
                } else {
                    this.errorMessage = '';
                    this.createColumn();
                }
            },
            async createColumn() {
                try {
                    const res = await this.service.save({ kanban_column: { name: this.newColumnName } });

                    if (res.status == 201) {
                        this.$Notify({
                            type: 'success',
                            title: this.$t('notification.save.success.title'),
                            message: this.$t('notification.save.success.message'),
                        });

                        this.$emit('close');
                    }
                } catch (error) {
                    console.error('Error create Kanban Column:', error);
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal {
        background: white;
        padding: 20px;
        border-radius: 8px;
        width: 300px;
        max-width: 100%;
        position: relative;

        .theme-dark & {
            background-color: #333;
            color: #ffa500;
        }
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: #333;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .close-button:hover {
        color: #000;
    }

    .modal-actions {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .new-column-form input {
        width: 100%;
        margin-bottom: 15px;
    }

    .error-message {
        color: red;
        font-size: 12px;
    }
</style>
