<template>
    <div class="kanban">
        <div class="kanban__content-wrapper">
            <router-view :key="$route.fullPath" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Kanban',
    };
</script>

<style lang="scss" scoped></style>
