<template>
    <div ref="invoiceForm" :class="{ 'overflow-auto': isOverflow }" class="invoice-form">
        <div class="header">
            <h2>{{ isEditMode ? $t('filters.editTitle') : $t('filters.uploadTitle') }}</h2>
            <button
                v-if="isEditMode"
                type="button"
                class="back-button at-btn crud__control-items__item at-btn--primary at-btn--default at-btn--large"
                @click="goBack"
            >
                {{ $t('actions.back') }}
            </button>
        </div>
        <form class="form" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group">
                    <label for="invoice" class="invoice-label">
                        {{ $t('filters.choose') }}
                    </label>
                    <input
                        id="invoice"
                        type="file"
                        accept=".pdf,.png,.jpeg,.jpg,.tiff,.heic,.doc,.docx,.ads,.xml"
                        class="invoice-input"
                        @change="handleFileChange"
                    />
                </div>
                <div class="form-group">
                    <label for="date" class="invoice-label">
                        {{ $t('filters.date') }}
                    </label>
                    <input id="date" v-model="form.date" type="date" class="invoice-input" />
                </div>
                <div class="form-group">
                    <label for="date" class="invoice-label">
                        {{ $t('filters.paymentDate') }}
                    </label>
                    <input id="date" v-model="form.paymentDate" type="date" class="invoice-input" />
                </div>
                <div class="form-group">
                    <label for="amount" class="invoice-label">
                        {{ $t('filters.amount') }}
                    </label>
                    <input id="amount" v-model="form.amount" type="number" step="0.01" class="invoice-input" />
                </div>
                <div class="form-group">
                    <label for="currency" class="invoice-label">
                        {{ $t('filters.currency') }}
                    </label>
                    <select id="currency" v-model="form.amountCurrency" class="invoice-input">
                        <option v-for="currency in currencies" :key="currency" :value="currency">
                            {{ currency }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="invoiceCategory" class="invoice-label">
                        {{ $t('filters.invoiceCategory') }}
                    </label>
                    <select id="invoiceCategory" v-model="form.invoiceCategoryId" class="invoice-input">
                        <option
                            v-for="category in invoiceCategories"
                            :key="category.attributes.id"
                            :value="category.attributes.id"
                        >
                            {{ category.attributes.name }}
                        </option>
                    </select>
                </div>
                <div v-if="tags" class="form-group">
                    <div for="tags" class="invoice-label">
                        {{ $t('filters.tags') }}
                    </div>
                    <TagMultiplyFilter
                        :availableTags="tags"
                        :selectedInvoiceTags="invoice.tags"
                        @update:selectedTags="handleTagFilterChange"
                    />
                </div>
                <div v-if="isEditMode" class="form-group form-checkbox">
                    <div class="custom-checkbox">
                        <div class="currency-conversion-controls">
                            <label for="convert-currency" class="custom-checkbox">
                                <input id="convert-currency" v-model="form.paid" type="checkbox" />
                                <span class="checkbox-mark"></span>
                                {{ $t('filters.paymentStatus') }}
                            </label>
                        </div>
                    </div>
                    <div class="color-pay-indicator">
                        <div class="color-indicator" :class="{ paid: form.paid }">*</div>
                    </div>
                </div>
                <div class="form-group">
                    <button
                        type="submit"
                        class="submit-button at-btn crud__control-items__item at-btn--primary at-btn--default at-btn--large"
                    >
                        {{ isEditMode ? $t('actions.update') : $t('actions.upload') }}
                    </button>
                </div>
            </div>
        </form>

        <div v-if="previewUrl" class="file-preview">
            <img v-if="isImagePreview || isTiffPreview" :src="previewUrl" alt="File Preview" />
            <iframe v-if="isPdfPreview" :src="previewUrl" width="100%" height="600px"></iframe>
            <p v-if="!isImagePreview && !isTiffPreview && !isPdfPreview">{{ $t('filters.previewNotAvailable') }}</p>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import InvoiceService from '../services/invoice.service';
    import TagMultiplyFilter from './TagMultiplyFilter.vue';
    import heic2any from 'heic2any';
    import Tiff from 'tiff.js';

    export default {
        name: 'UploadForm',
        components: { TagMultiplyFilter },
        props: {
            invoice: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                form: {
                    invoiceFile: null,
                    date: null,
                    amount: null,
                    amountCurrency: null,
                    tagIDs: [],
                    invoiceCategoryId: null,
                    paid: null,
                    paymentDate: null,
                },
                service: new InvoiceService(),
                tags: null,
                invoiceCategories: null,
                previewUrl: null,

                isImagePreview: false,
                isTiffPreview: false,
                isPdfPreview: false,

                isOverflow: false,
            };
        },
        computed: {
            ...mapGetters('currency', ['currencies']),
            isEditMode() {
                return !!this.invoice.id;
            },
        },
        watch: {
            invoice: {
                handler(newInvoice) {
                    this.form.date = newInvoice.date;
                    if (newInvoice.amount) {
                        this.form.amount = newInvoice.amount_value;
                    }
                    this.form.amountCurrency = newInvoice.amount_currency;
                    this.form.invoiceCategoryId = newInvoice.invoice_category_id;
                    this.form.paid = newInvoice.paid;
                    this.form.paymentDate = newInvoice.payment_date;
                },
                immediate: true,
            },
        },
        mounted() {
            this.resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    this.isOverflow = entry.target.offsetHeight >= 700;
                }
            });
            this.resizeObserver.observe(this.$refs.invoiceForm);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.checkOverflow);
        },
        async created() {
            this.fetchInvoiceCategoriesData();
            const tagsList = await this.$store.dispatch('tag/getTags');
            this.tags = tagsList;
        },
        methods: {
            async fetchInvoiceCategoriesData() {
                this.invoiceCategories = await this.$store.dispatch('invoiceCategory/getInvoiceCategories');
            },
            async handleFileChange(event) {
                const file = event.target.files[0];
                if (!file) return;
                this.form.invoiceFile = file;

                const fileExtension = file.name.split('.').pop().toLowerCase();

                if (['png', 'jpeg', 'jpg'].includes(fileExtension)) {
                    this.isImagePreview = true;
                    this.isTiffPreview = false;
                    this.isPdfPreview = false;
                    this.previewUrl = URL.createObjectURL(file);
                } else if (fileExtension === 'heic') {
                    this.isImagePreview = true;
                    this.isTiffPreview = false;
                    this.isPdfPreview = false;
                    try {
                        const blob = await heic2any({ blob: file, toType: 'image/jpeg' });
                        this.previewUrl = URL.createObjectURL(blob);
                    } catch (error) {
                        console.error('Error converting HEIC file:', error);
                    }
                } else if (fileExtension === 'tiff') {
                    this.isImagePreview = false;
                    this.isTiffPreview = true;
                    this.isPdfPreview = false;

                    try {
                        const arrayBuffer = await file.arrayBuffer();
                        const tiff = new Tiff({ buffer: arrayBuffer });

                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');

                        canvas.width = tiff.width();
                        canvas.height = tiff.height();

                        context.drawImage(tiff.toCanvas(), 0, 0);

                        this.previewUrl = canvas.toDataURL('image/png');
                    } catch (error) {
                        console.error('Error rendering TIFF file:', error);
                    }
                } else if (fileExtension === 'pdf') {
                    this.isImagePreview = false;
                    this.isTiffPreview = false;
                    this.isPdfPreview = true;
                    this.previewUrl = URL.createObjectURL(file);
                } else {
                    this.previewUrl = null;
                }
            },
            handleTagFilterChange(tags) {
                const tagIDs = tags.map(tag => tag.id);
                this.form.tagIDs = tagIDs;
            },
            async handleSubmit() {
                try {
                    const formData = new FormData();
                    if (this.form.invoiceFile) formData.append('invoice', this.form.invoiceFile);
                    if (this.form.date) formData.append('date', this.form.date);
                    if (this.form.amount) formData.append('amount_cents', this.form.amount);
                    if (this.form.amountCurrency) formData.append('amount_currency', this.form.amountCurrency);
                    if (this.form.invoiceCategoryId) {
                        formData.append('invoice_category_id', this.form.invoiceCategoryId);
                    }
                    if (this.form.paid != null) formData.append('paid', this.form.paid);
                    if (this.form.paymentDate) formData.append('payment_date', this.form.paymentDate);

                    this.form.tagIDs.forEach(tagID => {
                        formData.append('tag_ids[]', tagID);
                    });

                    const { data } = this.isEditMode
                        ? await this.service.update(formData, this.invoice.id)
                        : await this.service.save(formData);

                    this.$emit('formSubmitted');
                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.save.success.title'),
                        message: this.$t('notification.save.success.message'),
                    });
                    this.$router.push({ path: `/invoice/view/${data.data.id}` });
                } catch (error) {
                    console.error('Error submitting form:', error);
                }
            },
            goBack() {
                this.$router.go(-1);
            },
            checkOverflow() {
                const formHeight = this.$refs.invoiceForm.clientHeight;
                this.isOverflow = formHeight >= 700;
            },
        },
    };
</script>

<style scoped lang="scss">
    .invoice-form {
        width: 99%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;

        &.overflow-auto {
            overflow-y: auto;
            max-height: 700px;
        }

        .theme-dark & {
            background-color: #333;
            color: #ffa500;
        }
    }

    .color-indicator {
        color: red;
        max-width: 100px;
        width: 99%;
        height: 44px;
        background-color: red;
        border-radius: 5px;
    }

    .paid {
        background-color: #4caf50;
        color: #4caf50;
    }

    .form-checkbox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
    }

    .color-pay-indicator {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .custom-checkbox {
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        input[type='checkbox'] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkbox-mark {
            position: relative;
            height: 20px;
            width: 20px;
            min-width: 20px;
            background-color: #ccc;
            border-radius: 4px;
            margin-right: 10px;
            transition: background-color 0.3s ease;

            &:after {
                content: '';
                position: absolute;
                display: none;
                left: 7px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

        input:checked + .checkbox-mark {
            background-color: #4caf50;

            &:after {
                display: block;
            }
        }

        &:hover .checkbox-mark {
            background-color: #999;
        }
    }

    .file-preview {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        max-height: 100%;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            border: 1px solid #ccc;
            border-radius: 5px;
        }

        p {
            font-size: 14px;
            color: #555;
        }

        .theme-dark p {
            color: #ffa500;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    h2 {
        color: #333;
        text-align: center;
        margin: 0;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .form {
        width: 100%;
    }

    .form-row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 20px;
    }

    .form-group {
        flex: 1;
        min-width: 150px;
        position: relative;
    }

    .invoice-label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        color: #555;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .icon {
        margin-right: 5px;
    }

    .invoice-input {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: #007bff;
            box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
        }

        .theme-dark & {
            background-color: #555;
            color: #ffa500;
            border-color: #444;

            &:focus {
                border-color: #66afe9;
                box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
            }
        }
    }

    @media (max-width: 1400px) {
        .invoice-form {
            display: grid;
            max-width: 700px;
            width: 99%;
        }
    }

    @media (max-width: 768px) {
        .form-row {
            flex-wrap: wrap;
        }

        ::v-deep .multiselect__tags {
            width: 99%;
        }

        .submit-button {
            width: 99%;
        }
    }
</style>
