<template>
    <div ref="tagSelector" class="tag-selector" @click.stop>
        <button
            class="at-btn crud__control-items__item at-btn--primary at-btn--default at-btn--large"
            @click.stop="toggleTagWindow"
        >
            {{ $t('actions.tags') }}
        </button>
        <div v-if="showTagWindow" :class="['tag-window', theme]">
            <label v-for="tag in availableTags" :key="tag.attributes.id" class="tag-option">
                <input v-model="selectedTags" type="checkbox" :value="tag.attributes.id" @change="updateTags" />
                <span :style="{ backgroundColor: tag.attributes.color }" class="tag-color"></span>
                {{ tag.attributes.name }}
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            initialSelectedTags: {
                type: Array,
                default: () => [],
            },
            invoiceId: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                showTagWindow: false,
                selectedTags: [],
                availableTags: [],
                theme: localStorage.theme,
            };
        },
        mounted() {
            this.fetchTags();
            this.selectedTags = this.initialSelectedTags.map(tag => tag.id);
            document.addEventListener('click', this.handleClickOutside);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
        },
        watch: {
            initialSelectedTags(newTags) {
                this.selectedTags = newTags.map(tag => tag.id);
            },
        },
        methods: {
            async fetchTags() {
                this.availableTags = await this.$store.dispatch('tag/getTags');
            },
            toggleTagWindow() {
                this.showTagWindow = !this.showTagWindow;
            },
            updateTags() {
                this.$emit('update-tags', this.invoiceId, this.selectedTags);
            },
            handleClickOutside(event) {
                if (this.$refs.tagSelector && !this.$refs.tagSelector.contains(event.target)) {
                    this.showTagWindow = false;
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .tag-selector {
        position: relative;
        display: inline-block;
    }

    .tag-icon {
        cursor: pointer;
    }

    .tag-window {
        position: absolute;
        top: 30px;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        .theme-dark & {
            background-color: #333;
            color: #ffa500;
        }
    }

    .tag-option {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .tag-option:last-child {
        margin-bottom: 0;
    }

    .tag-option input {
        margin-left: 5px;
    }

    .tag-color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
        margin-left: 5px;
    }

    .tag-option:hover {
        .theme-dark & {
            background-color: #1f1f1f;
        }
    }

    .selected-tags {
        margin-top: 5px;
    }

    .selected-tag {
        display: inline-block;
        background-color: #e0f7fa;
        border-radius: 5px;
        padding: 2px 8px;
        margin-right: 5px;
        font-size: 12px;
    }
</style>
