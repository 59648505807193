<template>
    <div>
        <div>
            <UploadForm :invoice="invoice" @formSubmitted="handleFormSubmitted" />
        </div>
        <div class="invoice-show">
            <div class="invoice-body">
                <div v-if="isImageFile">
                    <img
                        v-if="isHeicFile"
                        :src="convertedImage"
                        class="invoice-image"
                        alt="Invoice Image"
                        @click="showImageModal('converted')"
                    />
                    <img
                        v-else-if="isTiffFile"
                        :src="convertedImage"
                        class="invoice-image"
                        alt="Invoice Image"
                        @click="showImageModal('converted')"
                    />
                    <img
                        v-else
                        :src="invoiceUrl"
                        class="invoice-image"
                        alt="Invoice Image"
                        @click="showImageModal('original')"
                    />
                </div>
                <div v-else-if="isPdfFile" class="pdf-file">
                    <object :data="invoiceUrl" type="application/pdf" class="pdf-viewer">
                        <a :href="invoiceUrl" class="invoice-image" target="_blank">View PDF</a>
                    </object>
                </div>
                <div v-else>
                    <a :href="invoiceUrl" target="_blank" class="document-link">Download Document</a>
                </div>
            </div>
            <div v-if="isModalVisible" class="modal" @click="hideImageModal">
                <div class="modal-content" @click.stop>
                    <span class="close-button" @click="hideImageModal">&times;</span>
                    <div class="image-container">
                        <img
                            ref="modalImage"
                            :src="modalImageSrc"
                            class="modal-image"
                            alt="Invoice Image"
                            @mousedown="startDrag"
                            @mousemove="onDrag"
                            @mouseup="endDrag"
                            @mouseleave="endDrag"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import heic2any from 'heic2any';
    import Tiff from 'tiff.js';
    import InvoiceService from '@/modules/invoice/services/invoice.service';
    import UploadForm from '@/modules/invoice/components/UploadForm.vue';

    export default {
        components: {
            UploadForm,
        },
        data() {
            return {
                invoice: {},
                service: new InvoiceService(),
                isModalVisible: false,
                isDragging: false,
                convertedImage: '',
                dragStartX: 0,
                dragStartY: 0,
                imgTranslateX: 0,
                imgTranslateY: 0,
                modalImageType: '',
            };
        },
        created() {
            this.fetchInvoice();
        },

        computed: {
            invoiceUrl() {
                if (this.invoice.invoice_url) {
                    return `${process.env.VUE_APP_API_URL}/${this.invoice.invoice_url}`;
                }
                return '';
            },
            isImageFile() {
                const ext = this.getFileExtension(this.invoiceUrl);
                return ['png', 'jpeg', 'jpg', 'tiff', 'heic'].includes(ext);
            },
            isPdfFile() {
                return this.getFileExtension(this.invoiceUrl) == 'pdf';
            },
            isHeicFile() {
                return this.getFileExtension(this.invoiceUrl) == 'heic';
            },
            isTiffFile() {
                return this.getFileExtension(this.invoiceUrl) == 'tiff';
            },
            modalImageSrc() {
                return this.modalImageType === 'converted' ? this.convertedImage : this.invoiceUrl;
            },
        },
        watch: {
            invoiceUrl: {
                immediate: true,
                handler(newUrl) {
                    if (this.isHeicFile) {
                        this.convertHeicImage(newUrl);
                    } else if (this.isTiffFile) {
                        this.convertTiffImage(newUrl);
                    }
                },
            },
        },
        methods: {
            async fetchInvoice() {
                try {
                    const { data } = await this.service.getById(this.$route.params.id);
                    this.invoice = data.data.attributes;
                } catch (error) {
                    console.error('Error fetching invoice:', error);
                }
            },
            async convertHeicImage(url) {
                try {
                    const response = await fetch(url);
                    const blob = await response.blob();
                    const result = await heic2any({
                        blob,
                        toType: 'image/jpeg',
                    });
                    this.convertedImage = URL.createObjectURL(result);
                } catch (error) {
                    console.error('Error converting HEIC image:', error);
                }
            },
            async convertTiffImage(url) {
                try {
                    const response = await fetch(url);
                    const blob = await response.blob();
                    const tiff = new Tiff({ buffer: await blob.arrayBuffer() });
                    const canvas = tiff.toCanvas();
                    this.convertedImage = canvas.toDataURL('image/jpeg');
                } catch (error) {
                    console.error('Error converting TIFF image:', error);
                }
            },
            getFileExtension(url) {
                return url.split('.').pop().toLowerCase();
            },
            handleFormSubmitted() {
                this.$router.push({ name: 'invoice.show', params: { id: this.invoice.id } });
            },
            goBack() {
                this.$router.go(-1);
            },
            showImageModal() {
                this.isModalVisible = true;
            },
            hideImageModal() {
                this.isModalVisible = false;
                this.resetImagePosition();
            },
            startDrag(event) {
                this.isDragging = true;
                this.dragStartX = event.clientX;
                this.dragStartY = event.clientY;
            },
            onDrag(event) {
                if (!this.isDragging) return;
                const dx = event.clientX - this.dragStartX;
                const dy = event.clientY - this.dragStartY;
                this.imgTranslateX += dx;
                this.imgTranslateY += dy;
                this.dragStartX = event.clientX;
                this.dragStartY = event.clientY;
                this.updateImageTransform();
            },
            endDrag() {
                this.isDragging = false;
            },
            updateImageTransform() {
                this.$refs.modalImage.style.transform = `scale(1.5) translate(${this.imgTranslateX}px, ${this.imgTranslateY}px)`;
            },
            resetImagePosition() {
                this.imgTranslateX = 0;
                this.imgTranslateY = 0;
                this.$refs.modalImage.style.transform = '';
            },
        },
    };
</script>

<style scoped lang="scss">
    .pdf-file {
        width: 99%;
    }
    .invoice-show {
        margin: 20px;
        font-family: Arial, sans-serif;

        .invoice-header {
            background-color: #f5f5f5;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;

            .invoice-header-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                h1 {
                    font-size: 24px;
                    margin: 0;
                }

                .back-button {
                    background-color: #007bff;
                    color: white;
                    margin-top: 25px;
                    border: none;
                    padding: 10px 20px;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 16px;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: #0056b3;
                    }
                }
            }

            .invoice-details {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;

                p {
                    margin: 0;
                    font-size: 16px;

                    strong {
                        color: #333;
                    }
                }
            }
        }

        .invoice-body {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;

            .invoice-image {
                max-width: 100%;
                max-height: 80vh;
                border-radius: 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                cursor: pointer;
                transition: transform 0.3s;

                &:hover {
                    transform: scale(1.05);
                }
            }

            .pdf-viewer {
                width: 100%;
                height: 80vh;
                border-radius: 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                background-color: #f5f5f5;
            }

            .document-link {
                color: #007bff;
                text-decoration: none;
                font-size: 16px;

                &:hover {
                    text-decoration: underline;
                }

                .theme-dark & {
                    color: #ffa500;
                }
            }
        }

        .modal {
            position: fixed;
            z-index: 1000;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;

            .modal-content {
                position: relative;
                padding: 20px;
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                display: flex;
                justify-content: center;
                align-items: center;

                .close-button {
                    position: absolute;
                    top: 10px;
                    right: 25px;
                    color: #fff;
                    font-size: 35px;
                    font-weight: bold;
                    cursor: pointer;
                    transition: color 0.3s;

                    &:hover,
                    &:focus {
                        color: #bbb;
                    }
                }

                .image-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;

                    .modal-image {
                        max-width: 100%;
                        max-height: 80vh;
                        cursor: move;
                        border-radius: 8px;
                        transition: transform 0.3s;
                    }
                }
            }
        }
    }
</style>
