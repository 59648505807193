<template>
    <div class="kanban-board">
        <div class="header-buttons-panel">
            <button class="filter-button" @click="openFilterModal">
                <i class="at-btn__icon icon icon-filter"></i>
            </button>
            <button v-if="buttonAccess" class="settings-button" @click="openSettings">
                <i class="at-btn__icon icon icon-edit"></i>
            </button>
        </div>
        <kanban-board-modal v-if="showModal" :columns="columns" @close="closeSettings" />

        <kanban-column
            v-for="column in columns"
            :key="column.attributes.id"
            :currentColumn="column"
            :tasks="tasks"
            :usedStatusesID="usedStatusesID"
            @moveCard="moveCard"
            @dragStart="setDraggingInfo"
            @drag-hover="setTargetInfo"
        />

        <FilterModal :isOpen="isFilterModalOpen" @close="closeFilterModal" @selected-project="handleFetchColumns" />
    </div>
</template>

<script>
    import KanbanColumn from './KanbanColumn.vue';
    import KanbanColumnService from '../../services/kanban-column.service';
    import { debounce } from 'lodash';
    import TasksService from '../../../../services/resource/task.service';
    import KanbanBoardModal from '../../components/kanbanBoardModal.vue';
    import RolePolicy from '../../../../policies/role.policy';
    import FilterModal from '../../components/FilterModal.vue';
    import { mapGetters } from 'vuex';

    const localStorageKey = 'ust.local.storage.kanban_project_select';

    let reconnectInterval = 5000;
    let maxRetries = 10;
    let retries = 0;

    export default {
        components: {
            KanbanColumn,
            KanbanBoardModal,
            FilterModal,
        },
        data() {
            const storedProjectId = JSON.parse(localStorage.getItem(localStorageKey));

            return {
                columns: [],
                tasks: null,
                draggingInfo: {
                    card: null,
                    fromColumn: null,
                    fromStatus: null,
                },
                targetInfo: {
                    targetCard: null,
                },
                usedStatusesID: [],
                selectedProjectId: storedProjectId || [],
                showModal: false,
                isFilterModalOpen: false,
                kanbanColumnService: new KanbanColumnService(),
                taskService: new TasksService(),
                wsUrl: process.env.VUE_APP_WS_URL || '',
                ws: null,
            };
        },
        async created() {
            await this.fetchTasks();
            await this.fetchKanbanColumns();
            this.connectWebSocket();
        },
        computed: {
            ...mapGetters('company', ['company']),
            buttonAccess() {
                return RolePolicy.haveRole(['admin']);
            },
        },
        methods: {
            setDraggingInfo(card, fromColumn, fromStatus) {
                this.draggingInfo = { card, fromColumn, fromStatus };
            },
            setTargetInfo(targetCard) {
                this.targetInfo = { targetCard };
            },
            connectWebSocket() {
                if (!this.wsUrl) return;

                this.ws = new WebSocket(this.wsUrl);

                this.ws.onopen = () => {
                    retries = 0;

                    const subTask = {
                        command: 'subscribe',
                        identifier: JSON.stringify({
                            channel: 'TaskChannel',
                            company_id: this.company.id,
                        }),
                    };
                    const subBoard = {
                        command: 'subscribe',
                        identifier: JSON.stringify({
                            channel: 'BoardChannel',
                            company_id: this.company.id,
                        }),
                    };
                    this.ws.send(JSON.stringify(subTask));
                    this.ws.send(JSON.stringify(subBoard));
                };

                this.ws.onmessage = event => {
                    const data = JSON.parse(event.data);
                    if (data.type !== 'ping' && data.message) {
                        const { action, task, params, board, status, task_id, status_id, column_id, board_id } =
                            data.message;

                        const actions = {
                            change_status: () => this.wsUpdateTaskStatus(task, params),
                            change_position: () => this.wsUpdateTasksPosition(params),
                            new_board: () => this.wsUpdateKanbanColumns(board.data),
                            remove_board: () => this.wsRemoveColumnFromArray(board_id),
                            update_board: () => this.wsUpdateColumnsArray(board.data),
                            remove_status: () => this.wsRemoveStatusIdFromUsedStatusesArray(status_id, column_id),
                            add_status: () => this.wsAddStatusIdToUsedStatusesArray(status.data.attributes, column_id),
                            new_task: () => this.wsAddNewTaskToBoard(task.data),
                            update_task: () => this.wsUpdateTaskParams(task.data),
                            remove_task: () => this.wsRemoveTask(task_id),
                        };

                        if (actions[action]) {
                            actions[action]();
                        } else {
                            console.warn(`Unhandled action: ${action}`);
                        }
                    }
                };

                this.ws.onclose = () => {
                    console.log('WebSocket connection closed');
                    this.retryWebSocketConnection();
                };

                this.ws.onerror = error => {
                    console.error('WebSocket error:', error);
                    this.retryWebSocketConnection();
                };
            },

            retryWebSocketConnection() {
                if (retries < maxRetries) {
                    retries += 1;
                    console.log(`Try connecting (${retries}/${maxRetries})...`);
                    setTimeout(() => {
                        this.connectWebSocket();
                    }, reconnectInterval);
                } else {
                    console.log('The maximum number of reconnections has been reached.');
                }
            },

            sendMessageToWebSocket(taskID, newStatusID, position, params) {
                const message = {
                    command: 'message',
                    identifier: JSON.stringify({
                        channel: 'TaskChannel',
                        company_id: this.company.id,
                    }),
                    data: JSON.stringify({
                        action: 'change_status',
                        task_id: taskID,
                        new_status_id: newStatusID,
                        position: position,
                        company_id: this.company.id,
                        params: params,
                    }),
                };

                if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                    this.ws.send(JSON.stringify(message));
                } else {
                    console.error('WebSocket is not connected');
                }
            },

            moveCard(toColumnId, toStatusId) {
                const { card, fromColumn, fromStatus } = this.draggingInfo;

                const toColumnObj = this.columns.find(column => column.attributes.id == toColumnId);
                const toStatusObj = toColumnObj.attributes.statuses.find(status => status.id == toStatusId);

                if (fromStatus === toStatusId) {
                    return this.moveCardPosition();
                }

                if (toStatusObj.cards.length === 0) {
                    card.attributes.position = 1;
                } else {
                    card.attributes.position = toStatusObj.cards.length + 1;
                }

                this.sendMessageToWebSocket(card.attributes.id, toStatusObj.id, card.attributes.position, {
                    from_column_id: fromColumn,
                    from_status_id: fromStatus,
                    to_column_id: toColumnId,
                    to_status_id: toStatusId,
                });

                this.clearDragInfo();
            },

            moveCardPosition() {
                const { card } = this.draggingInfo;
                const { targetCard } = this.targetInfo;

                this.sendMessageChangePosition(card, targetCard);

                this.clearDragInfo();
            },

            sendMessageChangePosition(card, targetCard) {
                const message = {
                    command: 'message',
                    identifier: JSON.stringify({
                        channel: 'TaskChannel',
                        company_id: this.company.id,
                    }),
                    data: JSON.stringify({
                        action: 'change_position',
                        task_id: card.attributes.id,
                        position: targetCard.attributes.position,
                        params: {
                            card: {
                                id: card.attributes.id,
                                position: targetCard.attributes.position,
                            },
                            targetCard: {
                                id: targetCard.attributes.id,
                                position: card.attributes.position,
                            },
                        },
                    }),
                };

                if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                    this.ws.send(JSON.stringify(message));
                } else {
                    console.error('WebSocket is not connected');
                }
            },

            clearDragInfo() {
                this.draggingInfo = { card: null, fromColumn: null, fromStatus: null };
                this.targetInfo = { card: null };
            },

            async updateTaskPosition(taskID, position) {
                await this.taskService.update({ task: { position: position } }, taskID);
            },

            fetchKanbanColumns: debounce(async function () {
                try {
                    const { data } = await this.kanbanColumnService.getAll();

                    this.addCardsToColumns(data.data);
                } catch (error) {
                    console.error('Error fetching Kanban Columns or Tasks:', error);
                }
            }, 500),

            addCardsToColumns(columns) {
                this.columns = columns.map(column => {
                    column.attributes.statuses = column.attributes.statuses.map(status => {
                        this.usedStatusesID.push(status.id);
                        status.cards = this.tasks.filter(task => task.attributes.status_id === status.id);
                        status.cards.sort((a, b) => a.attributes.position - b.attributes.position);
                        return status;
                    });
                    return column;
                });
            },

            async fetchTasks() {
                try {
                    const { data } = await this.taskService.getWithFilters({
                        disable_pagy: true,
                        where: { project_id: ['=', this.selectedProjectId] },
                    });
                    this.tasks = data.data;
                } catch (error) {
                    console.error('Error fetching Tasks:', error);
                }
            },

            async handleFetchColumns(projectID) {
                this.selectedProjectId = projectID;
                await this.fetchTasks();
                await this.addCardsToColumns(this.columns);
            },

            wsAddNewTaskToBoard(task) {
                const statusId = task.attributes.status_id;

                this.columns.forEach(column => {
                    column.attributes.statuses.forEach(status => {
                        if (status.id === statusId) {
                            status.cards.push(task);
                        }
                    });
                });
            },

            wsUpdateTaskParams(task) {
                this.columns.forEach(column => {
                    column.attributes.statuses.forEach(status => {
                        if (status.id == task.attributes.status_id) {
                            const cardIndex = status.cards.findIndex(card => card.attributes.id === task.attributes.id);

                            if (cardIndex !== -1) {
                                this.$set(status.cards, cardIndex, task);
                            }
                        }
                    });
                });
            },

            wsRemoveTask(taskID) {
                this.columns.forEach(column => {
                    column.attributes.statuses.forEach(status => {
                        const cardIndex = status.cards.findIndex(card => card.attributes.id === taskID);

                        if (cardIndex !== -1) {
                            status.cards.splice(cardIndex, 1);
                            this.$set(status, 'cards', [...status.cards]);
                        }
                    });
                });
            },

            wsAddStatusIdToUsedStatusesArray(status, columnID) {
                let currentColumn = this.columns.find(column => column.attributes.id == columnID);

                if (currentColumn) {
                    status.cards = [];
                    currentColumn.attributes.statuses.push(status);
                    this.$set(currentColumn.attributes, 'statuses', [...currentColumn.attributes.statuses]);
                    this.usedStatusesID.push(status.id);
                }
            },

            wsRemoveStatusIdFromUsedStatusesArray(statusID, columnID) {
                let currentColumn = this.columns.find(column => column.attributes.id == columnID);
                let updatedStatuses = currentColumn.attributes.statuses.filter(status => status.id != statusID);

                this.$set(currentColumn.attributes, 'statuses', updatedStatuses);
                this.usedStatusesID = this.usedStatusesID.filter(id => id != statusID);
            },

            wsUpdateKanbanColumns(newColumn) {
                this.columns.push(newColumn);
            },

            wsRemoveColumnFromArray(objectID) {
                this.columns = this.columns.filter(c => c.attributes.id != objectID);
            },

            wsUpdateColumnsArray(updatedColumn) {
                const oldPosition = this.columns.find(column => column.attributes.id === updatedColumn.attributes.id)
                    .attributes.position;
                const newPosition = updatedColumn.attributes.position;

                let updatedArray = this.columns.map(column => {
                    if (column.attributes.id === updatedColumn.attributes.id) {
                        column.attributes.name = updatedColumn.attributes.name;
                        column.attributes.position = newPosition;
                    }
                    return column;
                });

                if (newPosition < oldPosition) {
                    updatedArray = updatedArray.map(column => {
                        if (
                            column.attributes.position >= newPosition &&
                            column.attributes.position < oldPosition &&
                            column.attributes.id !== updatedColumn.attributes.id
                        ) {
                            column.attributes.position += 1;
                        }
                        return column;
                    });
                } else if (newPosition > oldPosition) {
                    updatedArray = updatedArray.map(column => {
                        if (
                            column.attributes.position <= newPosition &&
                            column.attributes.position > oldPosition &&
                            column.attributes.id !== updatedColumn.attributes.id
                        ) {
                            column.attributes.position -= 1;
                        }
                        return column;
                    });
                }

                updatedArray.sort((a, b) => a.attributes.position - b.attributes.position);

                this.addCardsToColumns(updatedArray);
            },

            wsUpdateTasksPosition(cardData) {
                const { card, targetCard } = cardData;

                this.columns.forEach(column => {
                    column.attributes.statuses.forEach(status => {
                        const cardToMove = status.cards.find(c => c.id === card.id);
                        const targetCardToMove = status.cards.find(c => c.id === targetCard.id);

                        if (cardToMove && targetCardToMove) {
                            cardToMove.attributes.position = card.position;
                            targetCardToMove.attributes.position = targetCard.position;

                            status.cards.sort((a, b) => a.attributes.position - b.attributes.position);
                            this.$set(status, 'cards', [...status.cards]);
                        }
                    });
                });
            },

            wsUpdateTaskStatus(task, params) {
                const fromColumnObj = this.columns.find(column => column.attributes.id == params.from_column_id);
                const fromStatusObj = fromColumnObj.attributes.statuses.find(
                    status => status.id == params.from_status_id,
                );

                const toColumnObj = this.columns.find(column => column.attributes.id == params.to_column_id);
                const toStatusObj = toColumnObj.attributes.statuses.find(status => status.id == params.to_status_id);

                fromStatusObj.cards = fromStatusObj.cards.filter(c => c.id != task.data.attributes.id);
                this.$set(fromStatusObj, 'cards', [...fromStatusObj.cards]);

                toStatusObj.cards.push(task.data);
                this.$set(toStatusObj, 'cards', [...toStatusObj.cards]);
            },

            openSettings() {
                this.showModal = true;
            },

            closeSettings() {
                this.showModal = false;
            },

            openFilterModal() {
                this.isFilterModalOpen = true;
            },

            closeFilterModal() {
                this.isFilterModalOpen = false;
            },
        },
    };
</script>

<style scoped lang="scss">
    .kanban-board {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding: 0px 15px 20px 15px;
        background-color: #f5f5f5;
        border-radius: 10px;
        overflow-x: auto;
        min-height: 400px;
        position: relative;

        .theme-dark & {
            background-color: #282828;
            color: #ffa500;
        }
    }

    .header-buttons-panel {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 5px 0px 5px;
    }

    .filter-button {
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: #333;

        &:hover {
            color: #000;
        }

        .theme-dark & {
            color: #ffa500;

            &:hover {
                color: #c4c4cf;
            }
        }
    }

    .settings-button {
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: #333;

        &:hover {
            color: #000;
        }

        .theme-dark & {
            color: #ffa500;

            &:hover {
                color: #c4c4cf;
            }
        }
    }
</style>
