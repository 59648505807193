<template>
    <div v-if="isMobile" class="mobile-version">
        <div class="buttons">
            <div class="left-buttons">
                <button
                    class="at-btn crud__control-items__item at-btn--primary at-btn--default at-btn--large"
                    @click="toggleShowGraph"
                >
                    {{ $t('actions.showGraph') }}
                </button>
            </div>
            <div class="right-buttons">
                <button
                    class="at-btn crud__control-items__item at-btn--primary at-btn--default at-btn--large"
                    @click="openUploadModal"
                >
                    {{ $t('actions.upload') }}
                </button>
                <UploadModal :visible="isModalVisible" @close="closeModal">
                    <UploadForm @upload-success="handleUploadSuccess" />
                </UploadModal>
            </div>
        </div>

        <div :class="['month-items', { hide: hideGraph }]">
            <div class="chart-container">
                <bar-chart ref="barChart" :chart-data="datacollection" :options="options"></bar-chart>
            </div>
        </div>

        <div class="top-controls">
            <div class="year-controls">
                <label for="year" class="year-label">{{ $t('actions.selectedYear') }}</label>
                <select
                    v-if="availableYears"
                    id="year"
                    v-model="selectedYear"
                    class="year-input"
                    @change="fetchInvoiceData"
                >
                    <option v-for="year in availableYears" :key="year" :value="year">{{ year }}</option>
                </select>
                <div class="currency-conversion-controls">
                    <div class="currency-conversion-controls">
                        <label for="convert-currency" class="custom-checkbox">
                            <input
                                id="convert-currency"
                                v-model="convertCurrency"
                                type="checkbox"
                                @change="convertAllToCompanyCurrency"
                            />
                            <span class="checkbox-mark"></span>
                            {{ $t('text.convertTo') }} {{ company.currency }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="summary-controls">
                <label for="sum" class="sum-spent">{{ $t('text.totalSpent') }}</label>
                <div class="sum__total">
                    <div v-for="(sum, currency) in allSums" :key="currency" class="items">
                        <span>{{ sum }} {{ currency }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="container">
        <div class="sidebar">
            <div class="controls">
                <label for="year">{{ $t('actions.selectedYear') }}</label>
                <select
                    v-if="availableYears"
                    id="year"
                    v-model="selectedYear"
                    class="year-input"
                    @change="changeYersScope"
                >
                    <option v-for="year in availableYears" :key="year" :value="year">{{ year }}</option>
                </select>
            </div>
            <div class="currency-conversion-controls">
                <div class="currency-conversion-controls">
                    <label for="convert-currency" class="custom-checkbox">
                        <input
                            id="convert-currency"
                            v-model="convertCurrency"
                            type="checkbox"
                            @change="convertAllToCompanyCurrency"
                        />
                        <span class="checkbox-mark"></span>
                        {{ $t('text.convertTo') }} {{ company.currency }}
                    </label>
                </div>
            </div>
            <div class="sum">
                <p>{{ $t('text.totalSpent') }}</p>
                <div class="sum__total">
                    <div v-for="(sum, currency) in allSums" :key="currency" class="items">
                        <span>{{ sum }} {{ currency }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="chart-container">
            <bar-chart ref="barChart" :chart-data="datacollection" :options="options"></bar-chart>
        </div>
    </div>
</template>

<script>
    import { Bar } from 'vue-chartjs/legacy';
    import { Chart, registerables } from 'chart.js';
    import InvoiceService from '../services/invoice.service';
    import { mapGetters } from 'vuex';
    import UploadForm from '../components/UploadForm.vue';
    import UploadModal from '../components/UploadModal.vue';

    Chart.register(...registerables);

    export default {
        components: {
            'bar-chart': Bar,
            UploadForm,
            UploadModal,
        },
        props: {
            invoiceScope: {
                type: Array,
                default: () => [],
            },
            yearScope: {
                type: Boolean,
                default: true,
            },
            ownInvoices: {
                type: Boolean,
                reuired: true,
            },
        },
        data() {
            return {
                convertCurrency: false,
                allSums: {},
                invoiceService: new InvoiceService(),
                selectedYear: new Date().getFullYear(),
                selectedCurrency: null,
                availableYears: null,
                isDataLoading: false,
                isModalVisible: false,
                hideGraph: true,
                invoices: null,
                isMobile: false,

                monthlyData: {},
                currencyColors: null,
                datacollection: {
                    labels: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                    ],
                    datasets: [
                        {
                            label: 'NaN',
                            backgroundColor: '#ffa500',
                            data: [],
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    aspectRatio: 423 / 211,
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                color: '#ffa500',
                            },
                            grid: {
                                color: '#555',
                            },
                        },
                        x: {
                            ticks: {
                                color: '#ffa500',
                            },
                            grid: {
                                color: '#555',
                            },
                        },
                    },
                },
            };
        },
        computed: {
            ...mapGetters('company', ['company']),
            ...mapGetters('user', ['companyData']),
            ...mapGetters('dashboard', ['theme']),
            calculateSums() {
                const sums = {};

                Object.keys(this.monthlyData).forEach(currency => {
                    if (this.monthlyData[currency][this.selectedYear]) {
                        const total = this.monthlyData[currency][this.selectedYear].reduce((acc, val) => acc + val, 0);
                        sums[currency] = total.toFixed(2);
                    }
                });

                return sums;
            },
            startDate() {
                if (this.companyData.start_date_tax_year) {
                    return new Date(this.formatedDate(this.companyData.start_date_tax_year, this.selectedYear));
                }
                return new Date(`${this.selectedYear}-01-01`);
            },
            endDate() {
                if (this.companyData.start_date_tax_year) {
                    return new Date(this.formatedDate(this.companyData.start_date_tax_year, this.selectedYear + 1));
                }
                return new Date(`${this.selectedYear}-12-31`);
            },
            monthlyCurrencyData() {
                return this.monthlyData[this.selectedCurrency][this.selectedYear];
            },
        },
        async created() {
            this.currencyColors = this.$store.getters['currency/colors'];
            await this.fetchInvoiceData();
            this.chooseThemeColor();
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        },
        methods: {
            toggleShowGraph() {
                this.hideGraph = !this.hideGraph;
            },
            openUploadModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
            handleUploadSuccess() {
                this.closeModal();
            },
            getYearRange(startYear, endYear) {
                const years = [];
                for (let year = startYear; year <= endYear; year++) {
                    years.push(year);
                }
                return years;
            },
            async changeYersScope() {
                await this.fetchInvoiceData();
                this.convertCurrency = false;
            },
            chooseThemeColor() {
                const currentTheme =
                    this.theme === 'system'
                        ? window.matchMedia('(prefers-color-scheme: dark)').matches
                            ? 'dark'
                            : 'light'
                        : this.theme;
                if (currentTheme == 'light') {
                    this.options.scales.y.ticks.color = '#3F536E';
                    this.options.scales.x.ticks.color = '#3F536E';
                } else if (currentTheme == 'dark') {
                    this.options.scales.y.ticks.color = '#ffa500';
                    this.options.scales.x.ticks.color = '#ffa500';
                }
            },
            handleResize() {
                this.isMobile = window.innerWidth <= 550;
                if (window.innerWidth <= 550) {
                    this.datacollection.labels = [
                        this.$t('months.short.January'),
                        this.$t('months.short.February'),
                        this.$t('months.short.March'),
                        this.$t('months.short.April'),
                        this.$t('months.short.May'),
                        this.$t('months.short.June'),
                        this.$t('months.short.July'),
                        this.$t('months.short.August'),
                        this.$t('months.short.September'),
                        this.$t('months.short.October'),
                        this.$t('months.short.November'),
                        this.$t('months.short.December'),
                    ];
                } else {
                    this.datacollection.labels = [
                        this.$t('months.full.January'),
                        this.$t('months.full.February'),
                        this.$t('months.full.March'),
                        this.$t('months.full.April'),
                        this.$t('months.full.May'),
                        this.$t('months.full.June'),
                        this.$t('months.full.July'),
                        this.$t('months.full.August'),
                        this.$t('months.full.September'),
                        this.$t('months.full.October'),
                        this.$t('months.full.November'),
                        this.$t('months.full.December'),
                    ];
                }
                this.updateData();
            },
            formatedDate(dateString, year) {
                const date = new Date(dateString);
                const day = date.getUTCDate();
                const month = date.getUTCMonth() + 1;
                const formattedDay = String(day).padStart(2, '0');
                const formattedMonth = String(month).padStart(2, '0');
                return `${year}-${formattedMonth}-${formattedDay}`;
            },
            convertAllToCompanyCurrency() {
                if (this.convertCurrency) {
                    this.processConvertedAllInvoiceData();
                } else {
                    this.processInvoiceData();
                }
                this.updateData();
                this.updateAvailableYears();
            },
            updateSumDisplay() {
                this.allSums = this.calculateSums;
            },
            updateData() {
                this.selectedCurrency = this.company.currency;
                if (this.selectedCurrency && Object.keys(this.monthlyData).length > 0) {
                    this.datacollection.datasets = [];
                    Object.keys(this.monthlyData).forEach(currency => {
                        this.datacollection.datasets.push({
                            label: currency,
                            backgroundColor: this.currencyColors[currency],
                            data: this.monthlyData[currency][this.selectedYear] || [],
                        });
                    });
                    this.options.maintainAspectRatio = this.isMobile;
                } else {
                    this.datacollection.datasets = [];
                }

                this.updateSumDisplay();
                this.$nextTick(() => {
                    this.$refs.barChart.renderChart(this.datacollection, this.options);
                });
            },
            updateAvailableYears(startYear = 2020) {
                if (this.companyData.start_date_tax_year) {
                    startYear = new Date(this.companyData.start_date_tax_year).getFullYear();
                }
                const currentYear = new Date().getFullYear();
                this.availableYears = this.getYearRange(startYear, currentYear);
            },
            async fetchInvoiceData() {
                this.isDataLoading = true;
                try {
                    const { data } = await this.invoiceService.getWithFilters({
                        startDate: this.startDate,
                        endDate: this.endDate,
                        disable_pagy: true,
                        own: this.ownInvoices,
                        paid: true,
                    });

                    this.invoices = data.data;
                    this.processInvoiceData();
                    this.updateData();
                    this.updateAvailableYears();
                } catch (error) {
                    console.error('Error fetching filtered invoices:', error);
                } finally {
                    this.isDataLoading = false;
                }
            },
            processInvoiceData() {
                const monthlyData = {};

                this.invoices.forEach(invoice => {
                    const {
                        amount: { cents, currency_iso },
                        date,
                    } = invoice.attributes;

                    const month = new Date(date).getMonth();

                    if (!monthlyData[currency_iso]) {
                        monthlyData[currency_iso] = {};
                    }

                    if (!monthlyData[currency_iso][this.selectedYear]) {
                        monthlyData[currency_iso][this.selectedYear] = new Array(12).fill(0);
                    }

                    monthlyData[currency_iso][this.selectedYear][month] += cents / 100;
                });

                this.monthlyData = monthlyData;
            },
            processConvertedAllInvoiceData() {
                const monthlyData = {};

                this.invoices.forEach(invoice => {
                    const { date, company_currency_cents } = invoice.attributes;

                    const month = new Date(date).getMonth();

                    if (!monthlyData[this.selectedCurrency]) {
                        monthlyData[this.selectedCurrency] = {};
                    }

                    if (!monthlyData[this.selectedCurrency][this.selectedYear]) {
                        monthlyData[this.selectedCurrency][this.selectedYear] = new Array(12).fill(0);
                    }

                    monthlyData[this.selectedCurrency][this.selectedYear][month] += company_currency_cents / 100;
                });

                this.monthlyData = monthlyData;
            },
        },
        watch: {
            company(_value) {
                this.selectedCurrency = this.company.currency;
                this.updateData();
            },
            companyData(_value) {
                const startYear = new Date(this.companyData.start_date_tax_year).getFullYear();
                this.updateAvailableYears(startYear);
            },
            theme(newValue) {
                const currentTheme =
                    newValue === 'system'
                        ? window.matchMedia('(prefers-color-scheme: dark)').matches
                            ? 'dark'
                            : 'light'
                        : this.theme;
                if (currentTheme == 'light') {
                    this.options.scales.y.ticks.color = '#3F536E';
                    this.options.scales.x.ticks.color = '#3F536E';
                } else if (currentTheme == 'dark') {
                    this.options.scales.y.ticks.color = '#ffa500';
                    this.options.scales.x.ticks.color = '#ffa500';
                }
                this.updateData();
            },
            invoiceScope(newScope) {
                if (Object.keys(newScope).length != 0) {
                    this.selectedYear = new Date(newScope[0].attributes.date).getFullYear();
                    this.processInvoiceData(newScope);
                    this.updateData();
                    this.updateAvailableYears();
                }
            },

            async yearScope(yearScope) {
                if (yearScope) {
                    await this.fetchInvoiceData();
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .custom-checkbox {
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        input[type='checkbox'] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkbox-mark {
            position: relative;
            height: 20px;
            width: 20px;
            background-color: #ccc;
            border-radius: 4px;
            margin-right: 10px;
            transition: background-color 0.3s ease;

            &:after {
                content: '';
                position: absolute;
                display: none;
                left: 7px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

        input:checked + .checkbox-mark {
            background-color: #4caf50;

            &:after {
                display: block;
            }
        }

        &:hover .checkbox-mark {
            background-color: #999;
        }
    }
    .container {
        margin-bottom: 20px;
        display: flex;
        justify-content: left;
        gap: 20px;
        width: 100%;
        max-width: 1200px;
        max-height: 455px;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;

        .theme-dark & {
            background-color: #333;
            color: #ffa500;
        }
    }

    .sidebar {
        flex: 1;
        max-width: 200px;
        display: flex;
        flex-direction: column;
    }

    .chart-container {
        flex: 2;
        position: relative;
        height: 500px;
        width: 700px;
    }

    .chart-container canvas {
        max-height: 500px !important;
        max-width: 700px !important;
    }

    .controls {
        margin-bottom: 20px;

        label {
            font-weight: bold;
            margin-bottom: 10px;
            display: block;
        }
    }

    .currency-input {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: #007bff;
            box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
        }

        .theme-dark & {
            background-color: #555;
            color: #ffa500;
            border-color: #444;

            &:focus {
                border-color: #66afe9;
                box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
            }
        }
    }

    .year-input {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: #007bff;
            box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
        }

        .theme-dark & {
            background-color: #555;
            color: #ffa500;
            border-color: #444;

            &:focus {
                border-color: #66afe9;
                box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
            }
        }
    }

    .currency-conversion-controls {
        display: flex;
        margin-top: 10px;
    }

    .sum {
        margin-top: 20px;
        font-size: 1.2em;
        display: flex;
        flex-direction: column;
        justify-items: center;

        span {
            font-weight: bold;
            color: #3f536e;

            .theme-dark & {
                color: #ffa500;
            }
        }

        p {
            margin-bottom: 10px;
        }

        &__total {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            border: 1px solid #c4c4cf;
            background-color: #fff;
            height: auto;
            min-height: 42px;
            max-height: 250px;
            width: 100%;
            max-width: 300px;
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            .theme-dark & {
                border: 1px solid #555;
                background-color: #555;
            }
        }
    }
    @media (max-width: 1400px) {
        .container {
            flex-direction: column;
            gap: 20px;
            width: 100%;
            max-width: 1200px;
            padding: 20px;
            align-items: center;
            max-height: 535px;
            z-index: 10;
            margin-bottom: 50px !important;
        }

        .sidebar {
            order: 2;
            width: 100%;
            max-width: none;
            flex-direction: row;
            display: flex;
            justify-content: space-between;
        }

        .controls {
            text-align: left;
            flex: 1;
            max-width: 200px;
        }

        .sum {
            text-align: right;
            float: right;
            flex: 1;
            margin-top: 0;
            max-width: 200px;

            &__total {
                max-height: 43px;
                overflow-y: auto;
                overflow-x: hidden;
                display: block;
                padding-right: 5px;
            }

            .items {
                display: flex;
                justify-content: center;
                margin: 5px 0 12px 0;
            }
        }

        .chart-container {
            width: 99%;
            order: 1;
            flex: 1;
            max-height: 500px;
        }

        .chart-container canvas {
            width: 99% !important;
            height: auto !important;
        }
    }

    @media (max-width: 700px) {
        .custom-checkbox {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            margin: 0 5px 0 5px;
        }
    }

    @media (max-width: 550px) {
        .chart-container canvas {
            width: 99% !important;
            height: 99% !important;
        }

        .chart-container {
            width: 99%;
            order: 1;
            flex: 1;
            max-height: 230px;
        }

        .mobile-version {
            padding: 10px;
            margin-bottom: 20px;
            border-radius: 5px;
            width: 99%;

            .theme-dark & {
                background-color: #333;
            }
        }
        .top-controls {
            display: flex;
            margin-bottom: 10px;
            justify-content: space-between;
            gap: 10px;
            text-align: center;
        }
        .summary-controls {
            flex-direction: column;
            max-width: 150px;
            width: 99%;
        }

        .year-controls {
            flex-direction: column;
            max-width: 150px;
            width: 99%;
        }
        .year-label {
            margin-right: 5px;
        }
        .year-input {
            flex-grow: 1;
            margin-right: 20px;
        }
        .sum__total {
            background-color: #f0f0f0;
            border-radius: 5px;
            padding: 5px 10px;
            text-align: right;
        }

        .items {
            display: flex;
            justify-content: center;
            margin: 5px 0 12px 0;
        }

        .custom-checkbox {
            display: flex;
            flex-direction: row;
            min-width: 150px;
        }
        .buttons {
            display: flex;
            justify-content: space-between;
        }
        .right-buttons {
            display: flex;
        }
    }

    @media (max-width: 375px) {
        .mobile-version {
            padding: 10px;
            margin-bottom: 20px;
        }

        .month-item {
            flex: 1 1 calc(50% - 20px);
        }
    }
</style>
