<template>
    <div class="page-container">
        <h1 class="page-title">{{ clientObject?.name || 'Company' }} Users</h1>
        <ul v-if="users && users.length" class="user-table">
            <li class="user-row header-row">
                <div class="user-cell">Full Name</div>
                <div class="user-cell">Role In Company</div>
                <div class="user-cell">Email</div>
                <div class="user-cell">Phone</div>
            </li>
            <li v-for="user in users" :key="user.id" class="user-row">
                <div class="user-cell">{{ user.attributes.full_name }}</div>
                <div class="user-cell">{{ getUserRoles(user) }}</div>
                <div class="user-cell">{{ user.attributes.email }}</div>
                <div class="user-cell">{{ user.attributes.phone }}</div>
            </li>
        </ul>
        <p v-else class="no-data">{{ $t('common.no_data') }}</p>
        <div v-if="users && users.length" class="pagination">
            <at-pagination :total="totalItems" :current="page" :page-size="itemsPerPage" @page-change="onPageChange">
                <template #previous-text>{{ $t('pagination.previous') }}</template>
                <template #next-text>{{ $t('pagination.next') }}</template>
            </at-pagination>
        </div>
    </div>
</template>

<script>
    import ClientUsersService from '../../services/client-users.service';
    import ClientService from '../../../Client/services/client.service';

    export default {
        data() {
            return {
                users: null,
                clientObject: null,
                client_id: this.$route.params.id,
                clientService: new ClientService(),
                service: new ClientUsersService(),
                page: +(this.$route.query.page || 1),
                totalItems: 0,
                itemsPerPage: 15,
                queryParams: {
                    page: this.$route.query.page,
                },
            };
        },
        async created() {
            await this.fetchClient();
            await this.fetchClientCompanyUsers();
        },
        methods: {
            async fetchClientCompanyUsers() {
                try {
                    const res = await this.service.getAll({
                        client_company_id: this.clientObject.company_id,
                        page: this.page,
                    });
                    const { data, pagination } = res.data;
                    this.totalItems = pagination.total;
                    this.page = pagination.page;
                    this.itemsPerPage = 15;
                    this.users = data;
                } catch (error) {
                    console.error('Error fetching client users:', error);
                }
            },
            async fetchClient() {
                try {
                    const { data } = await this.clientService.getItem(this.client_id);
                    this.clientObject = data.data.attributes;
                } catch (error) {
                    console.error('Error fetching client:', error);
                }
            },
            getUserRoles(user) {
                const companyRoles = user.attributes.roles.find(
                    role => role.company_id == this.clientObject.company_id,
                );
                const roles = companyRoles.roles.map(role => role.attributes.name);
                return roles.length > 2 ? `${roles.slice(0, 2).join(', ')}...` : roles.join(', ');
            },
            async onPageChange(page) {
                if (this.queryParams.page === page) return;
                this.queryParams.page = page;
                this.page = page;
                await this.fetchClientCompanyUsers();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .page-container {
        padding: 20px;
        font-family: 'Poppins', sans-serif;
        color: #333333;
        min-height: 100vh;
        border-radius: 12px;
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    }

    .page-title {
        text-align: center;
        font-size: 2.5rem;
        font-weight: 600;
        color: #333333;
        margin-bottom: 20px;
    }

    .pagination {
        margin: 20px auto 0;
        display: flex;
        justify-content: flex-end;
        width: 80%;
    }

    .user-table {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        width: 80%;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .user-row {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px;
        border-bottom: 1px solid #ddd;
        background-color: #ffffff;
        transition: background-color 0.3s;
    }

    .user-row:hover {
        background-color: #f1f1f1;
    }

    .header-row {
        background-color: #e0e0e0;
        font-weight: bold;
        color: #333333;
        text-transform: uppercase;
    }

    .user-cell {
        flex: 2;
        text-align: left;
        padding: 8px 12px;
    }

    .user-cell:first-child {
        flex: 2;
    }
    .theme-dark {
        background-color: #1e1e2f;
        color: #e0e0e0;
    }

    .theme-dark .page-title {
        color: #ffffff;
    }

    .theme-dark .user-table {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    }

    .theme-dark .user-row {
        background-color: #333;
        border-bottom: 1px solid #2c2c3a;
    }

    .theme-dark .user-row:hover {
        background-color: #555;
    }

    .theme-dark .header-row {
        background-color: #222;
        color: #ffffff;
    }

    .theme-dark .user-cell {
        color: #ffa500;
    }

    .no-data {
        text-align: center;
        color: #999;
        font-size: 1.2rem;
        margin-top: 20px;
    }

    .theme-dark .no-data {
        color: #bbb;
    }

    @media (max-width: 768px) {
        .user-table {
            width: 100%;
        }

        .user-row {
            flex-wrap: wrap;
        }

        .user-cell {
            flex: 1 0 100%;
            padding: 8px 0;
        }

        .user-cell:first-child {
            flex: 1 0 100%;
        }
    }
</style>
