<template>
    <div class="document">
        <div class="document__content-wrapper">
            <router-view :key="$route.fullPath" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Document',
    };
</script>

<style lang="scss" scoped></style>
