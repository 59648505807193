<template>
    <div class="invoice">
        <div class="graph">
            <invoiceGrapth :yearScope="yearScope" :invoiceScope="invoiceScope" :ownInvoices="false"></invoiceGrapth>
        </div>
        <div class="list">
            <InvoiceList
                :ownInvoices="false"
                @scope-by-default="handleGraphScopeByDefault"
                @invoice-scope="handleInvoiceScope"
            ></InvoiceList>
        </div>
    </div>
</template>

<script>
    import InvoiceList from '../../components/InvoiceList';
    import InvoiceGrapth from '../../components/InvoiceGrapth.vue';

    export default {
        name: 'Invoice',
        components: {
            InvoiceList,
            InvoiceGrapth,
        },
        data() {
            return {
                yearScope: true,
                invoiceScope: null,
            };
        },
        methods: {
            handleGraphScopeByDefault() {
                this.yearScope = true;
            },
            handleInvoiceScope(scope) {
                this.yearScope = false;
                this.invoiceScope = scope;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .graph {
        margin-bottom: 20px;
    }

    @media (max-width: 550px) {
        .graph {
            height: auto;
        }
    }
</style>
