<template>
    <div class="edit-form">
        <create-form :documentID="documentID" />
    </div>
</template>

<script>
    import CreateForm from './New.vue';

    export default {
        components: {
            CreateForm,
        },
        data() {
            return {
                documentID: this.$route.params.document_id,
            };
        },
    };
</script>
