<template>
    <div v-if="isOpen" class="modal-overlay">
        <div class="modal-content">
            <div class="modal-header">
                <h4>{{ $t('components.kanbanColumnSettings.columnTitle') }} {{ columnName }}</h4>
                <button class="close-button" @click="closeModal">&#x2715;</button>
            </div>

            <div class="modal-body">
                <div class="form-group" :class="{ 'has-error': errors.columnName }">
                    <label for="columnName">{{ $t('components.kanbanColumnSettings.columnName') }}</label>
                    <at-input
                        id="columnName"
                        v-model="columnName"
                        type="text"
                        :placeholder="$t('components.kanbanColumnSettings.namePlaceholder')"
                        @input="clearError('columnName')"
                    />
                    <span v-if="errors.columnName" class="error-message">{{ errors.columnName }}</span>
                </div>

                <div class="form-group" :class="{ 'has-error': errors.columnPosition }">
                    <label for="columnPosition">{{ $t('components.kanbanColumnSettings.columnPosition') }}</label>
                    <at-input
                        id="columnPosition"
                        v-model="columnPosition"
                        type="number"
                        :placeholder="$t('components.kanbanColumnSettings.positionPlaceholder')"
                        @input="clearError('columnPosition')"
                    />
                    <span v-if="errors.columnPosition" class="error-message">{{ errors.columnPosition }}</span>
                </div>

                <div class="form-actions">
                    <button class="at-btn at-btn--primary at-btn--large action-button" @click="validateForm">
                        <i class="icon icon-save"></i> {{ $t('components.kanbanColumnSettings.buttons.save') }}
                    </button>
                    <button class="at-btn at-btn--error at-btn--large action-button" @click="handleDeleteColumn">
                        <i class="icon icon-trash"></i> {{ $t('components.kanbanColumnSettings.buttons.delete') }}
                    </button>
                </div>

                <h4>{{ $t('components.kanbanColumnSettings.statusTitle') }} {{ columnName }}</h4>
                <h5>Used Statuses</h5>
                <ul class="status-list">
                    <li v-for="status in columnStatuses" :key="status.id">
                        <label>
                            <input
                                type="checkbox"
                                class="custom-checkbox"
                                :checked="isActiveStatus(status)"
                                @change="toggleStatus(status)"
                            />
                            {{ status.name }}
                        </label>
                    </li>
                </ul>

                <div v-if="unusedStatuses.length != 0" class="unused-statuses">
                    <h5>Unused Statuses</h5>
                    <ul class="status-list">
                        <li v-for="status in unusedStatuses" :key="status.attributes.id">
                            <label>
                                <input
                                    type="checkbox"
                                    class="custom-checkbox"
                                    :checked="isActiveStatus(status.attributes)"
                                    @change="toggleStatus(status.attributes)"
                                />
                                {{ status.attributes.name }}
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <confirmation-modal
            v-if="showConfirmModal"
            :visible="showConfirmModal"
            :message="$t('components.kanbanColumnSettings.confirmDelete')"
            @confirm="deleteColumn"
            @close="closeConfirmModal"
        />
    </div>
</template>

<script>
    import StatusService from '../../Statuses/services/statuse.service';
    import KanbanColumnService from '../services/kanban-column.service';
    import ConfirmationModal from '../../../components/DeleteModal.vue';

    export default {
        components: {
            ConfirmationModal,
        },
        props: {
            isOpen: Boolean,
            currentColumn: Object,
            columnStatuses: Array,
            usedStatusesID: Array,
        },
        data() {
            return {
                columnID: this.currentColumn.attributes.id,
                columnName: this.currentColumn.attributes.name,
                columnPosition: this.currentColumn.attributes.position,
                statuses: [],
                errors: {
                    columnName: null,
                    columnPosition: null,
                },
                showConfirmModal: false,
                kanbanColumnService: new KanbanColumnService(),
                statusService: new StatusService(),
            };
        },
        computed: {
            unusedStatuses() {
                return this.statuses.filter(status => !this.usedStatusesID.includes(status.attributes.id));
            },
        },
        async created() {
            await this.fetchStatuses();
        },
        methods: {
            async fetchStatuses() {
                try {
                    const { data } = await this.statusService.getAll({ disable_pagy: true });
                    this.statuses = data.data.filter(status => status.attributes.visible);
                } catch (error) {
                    console.log('error request index status', error);
                }
            },

            isActiveStatus(status) {
                return this.columnStatuses.some(columnStatus => columnStatus.id === status.id);
            },

            toggleStatus(status) {
                if (this.isActiveStatus(status)) {
                    this.removeStatusFromColumn({
                        kanban_column_status: {
                            kanban_column_id: this.currentColumn.attributes.id,
                            status_id: status.id,
                        },
                    });
                } else {
                    this.addStatusToColumn({
                        kanban_column_status: {
                            kanban_column_id: this.currentColumn.attributes.id,
                            status_id: status.id,
                        },
                    });
                }
            },
            async addStatusToColumn(params) {
                try {
                    const res = await this.kanbanColumnService.addStatusToColumn(params);
                    if (res.status == 201) {
                        this.successMessage();
                    }
                } catch (error) {
                    console.log('error request add status to Column', error);
                }
            },

            async removeStatusFromColumn(params) {
                try {
                    const res = await this.kanbanColumnService.removeStatusFromColumn(params);
                    if (res.status == 204) {
                        this.successMessage();
                    }
                } catch (error) {
                    console.log('error request remove status from Column', error);
                }
            },
            validateForm() {
                this.errors.columnName = !this.columnName
                    ? this.$t('components.kanbanColumnSettings.errors.name')
                    : null;
                this.errors.columnPosition = !this.columnPosition
                    ? this.$t('components.kanbanColumnSettings.errors.position')
                    : null;

                if (!this.errors.columnName && !this.errors.columnPosition) {
                    this.submitForm();
                }
            },
            async submitForm() {
                try {
                    const formData = new FormData();
                    formData.append('kanban_column[name]', this.columnName);
                    formData.append('kanban_column[position]', this.columnPosition);
                    const res = await this.kanbanColumnService.update(formData, this.columnID);

                    if (res.status == 200) {
                        this.successMessage();
                    }
                } catch (error) {
                    console.log('Error updating Kanban Column', error);
                }
            },

            handleDeleteColumn() {
                this.showConfirmModal = true;
            },
            async deleteColumn() {
                this.showConfirmModal = false;
                try {
                    const res = await this.kanbanColumnService.delete(this.columnID);

                    if (res.status == 204) {
                        this.successMessage();
                        this.$emit('close');
                    }
                } catch (error) {
                    console.log('Error destroying column', error);
                }
            },

            closeModal() {
                this.$emit('close');
            },

            clearError(field) {
                this.errors[field] = null;
            },

            closeConfirmModal() {
                this.showConfirmModal = false;
            },

            successMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.save.success.title'),
                    message: this.$t('notification.save.success.message'),
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        width: 400px;
        max-width: 90%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: #333;
            color: #ffa500;

            &::v-deep .at-input__original {
                background-color: #555;
            }
        }
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal-body {
        padding: 10px 0;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .form-group.has-error input {
        border-color: red;
    }

    .error-message {
        color: red;
        font-size: 12px;
    }

    .form-actions {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .status-list {
        list-style-type: none;
        padding: 0;
        margin-bottom: 10px;
    }

    .status-list li {
        padding: 5px 0;
        display: flex;
        align-items: center;
    }

    .status-list label {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .status-list input[type='checkbox'] {
        margin-right: 10px;
    }

    .custom-checkbox {
        width: 20px;
        height: 20px;
        appearance: none;
        border: 2px solid #ccc;
        border-radius: 4px;
        display: inline-block;
        position: relative;
        cursor: pointer;
        outline: none;
    }

    .custom-checkbox:checked {
        background-color: #4caf50;
        border-color: #4caf50;
    }

    .custom-checkbox:checked::after {
        content: '\2714';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        color: white;
    }
</style>
