<template>
    <div v-if="visible" class="modal-overlay">
        <div class="modal-content">
            <button class="close-button" @click="$emit('close')">✖</button>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style scoped lang="scss">
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 20;
        width: 100%;
    }

    .modal-content {
        background: rgba(0, 0, 0, 0.1);
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;
        max-width: 800px;
        margin-left: 150px;

        @media (min-width: 1400px) {
            max-width: 1100px;
            margin-left: 200px;
        }
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 18px;
        cursor: pointer;
    }

    @media (max-width: 992px) {
        .modal-content {
            margin-left: 0px;
        }
    }
</style>
