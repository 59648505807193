<template>
    <div class="invoice">
        <div class="invoice__content-wrapper">
            <router-view :key="$route.fullPath" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Invoice',
    };
</script>

<style lang="scss" scoped></style>
