<template>
    <div v-if="isOpen" class="modal-overlay">
        <div class="modal-content">
            <div class="modal-header">
                <h2>Filters</h2>
                <button class="close-button" @click="closeModal">&#x2715;</button>
            </div>
            <div class="modal-body">
                <label for="project">Projects Select</label>
                <at-select
                    v-if="projects.length"
                    ref="select"
                    v-model="selectedProjectId"
                    placeholder="Select a project"
                >
                    <at-option :key="0" :label="'All'" :value="'all'" />
                    <at-option
                        v-for="project in projects"
                        :key="project.attributes.id"
                        :label="project.attributes.name"
                        :value="project.attributes.id"
                    />
                </at-select>
                <at-input v-else disabled placeholder="No projects available"></at-input>
            </div>
        </div>
    </div>
</template>

<script>
    import ProjectService from '../../../services/resource/project.service';

    const localStorageKey = 'ust.local.storage.kanban_project_select';

    export default {
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            const storedProjectId = JSON.parse(localStorage.getItem(localStorageKey));

            return {
                selectedProjectId: storedProjectId || 'all',
                projects: [],
                projectService: new ProjectService(),
            };
        },

        watch: {
            selectedProjectId(projectID) {
                if (projectID === 'all') {
                    localStorage.setItem(localStorageKey, JSON.stringify(null));
                } else {
                    localStorage.setItem(localStorageKey, JSON.stringify(projectID));
                }
                this.$emit('selected-project', projectID);
            },
        },

        async created() {
            await this.fetchProjects();
        },

        methods: {
            async fetchProjects() {
                try {
                    const { data } = await this.projectService.getWithFilters({ disable_pagy: true });
                    this.projects = data.data;
                } catch (error) {
                    console.log('Error request projects', error);
                }
            },
            closeModal() {
                this.$emit('close');
            },
        },
    };
</script>

<style scoped lang="scss">
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        width: 400px;
        max-width: 90%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: #333;
            color: #ffa500;

            &::v-deep .at-input__original {
                background-color: #555;
            }
        }
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal-body {
        padding: 10px 0;
    }
</style>
