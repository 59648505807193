<template>
    <div
        ref="card"
        class="kanban-card"
        draggable="true"
        :class="{ 'is-dragging': isDragging }"
        @dragstart="handleDragStart"
        @dragover.prevent="dragOverTargetCard"
        @dragenter.prevent="dragOverTargetCard"
    >
        <span class="ref-text">{{ card.attributes.ref }}:</span>
        <span class="task-name">{{ card.attributes.task_name }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            card: Object,
            columnID: Number,
            statusID: Number,
        },
        data() {
            return {
                isDragging: false,
            };
        },
        methods: {
            handleDragStart() {
                this.$emit('dragStart', this.card, this.columnID, this.statusID);
            },
            dragOverTargetCard() {
                this.$emit('drag-hover-card', this.card);
            },
        },
    };
</script>

<style scoped lang="scss">
    .kanban-card {
        padding: 12px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        cursor: grab;
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        &.is-dragging {
            z-index: 1000;
            cursor: grabbing;
        }

        .theme-dark & {
            background-color: #555;

            &:hover {
                transform: scale(1.02);
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
                background-color: #ffa60025;
            }
        }

        &:hover {
            transform: scale(1.02);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
            background-color: #00abc15d;
        }

        &:active {
            cursor: grabbing;
        }

        .ref-text {
            font-weight: bold;
            margin-right: 5px;
            flex-shrink: 0;
            white-space: nowrap;
        }

        .task-name {
            font-weight: normal;
            flex-shrink: 0;
        }
    }
</style>
